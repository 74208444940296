import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useMsal } from '@azure/msal-react'
import { useLocation } from 'react-router-dom'
import { useForm, Controller, SubmitHandler, FieldErrors } from 'react-hook-form'
import { Dialog, Transition } from '@headlessui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { IselectOptionGroupProps, IselectOptionProps } from 'forms/FormModelInterface'
import { CloseIcon, TickIcon, Spinner, DeleteIcon, AlertIcon } from 'assets/icons'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import TextInputControl from 'components/formComponents/TextInputControl'
import { SelectControl } from 'components/formComponents'
import { iBusinessUnitDetails } from 'data/Interfaces'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { useBusinessUnitsListWrapper } from 'data/BusinessUnitsListHookWrapper'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { CustomerStatusConstants, CustomerStatusEnums } from 'pages/customer/view/CustomerPageUtils'
import { operatorUpdateMutationKey } from 'services/apis/caching/operators'
import { EditOperatorFormProps, EditOperatorFormType } from './EditOperatorFormInterface'
import { iUpdateOperatorDTO, updateOperator } from '../data/EditOperatorAPI'
import { useDoesOperatorHaveAssignmentHistory } from '../data/DeleteOperatorAPI'
import ConfirmDeleteOperator from './ConfirmDeleteOperator'

function EditOperator({ rowData, setIsOpened }: EditOperatorFormProps): JSX.Element {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)

  /*
  Use the following API (write another hook)
  api/Device/Assignment/deviceUser/{deviceUserId}
  to check if the operator can be deleted

  This will return history of the device user assignment records
  If the operator has no history, then the operator can be deleted
  If the operator has history, then the operator cannot be deleted

  If the operator can be deleted, then set the following state to true

  */

  /*
  Then add the delete API separately
  on click of delete button.

  Follow pattern in the edit device page
  */

  const [canOperatorBeDeleted, setCanOperatorBeDeleted] = useState<boolean>(false)

  const msalContext = useMsal()

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<IselectOptionProps>({
    label: rowData.businessUnit.name || '',
    value: rowData.businessUnit.id || '',
  })

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const userAccountContext = useContext(UserAccountContext)
  const { state: userInfo } = userAccountContext
  const isGlobalUser = IsUserGlobalScope(userInfo)
  const customerId: string = isGlobalUser ? '' : ''
  const operatorId = rowData.id || ''
  const [businessUnitsListConverted, setBusinessUnitsListConverted] = useState<
    IselectOptionProps[]
  >([])

  const { genericEventHandler } = useGenericEventHandler()
  const defaultValues: EditOperatorFormType = {
    OperatorId: rowData.id,
    FirstName: rowData.firstName,
    LastName: rowData.lastName,
    BusinessUnit: selectedBusinessUnit,
    UniqueId: rowData.uniqueId,
    Status: {
      label: CustomerStatusConstants[rowData.status as CustomerStatusEnums],
      value: rowData.status,
    },
  }

  const {
    data: businessUnitsList,
    isLoading: isBusinessUnitsListLoading,
    isError: isBusinessUnitsListError,
    error: businessUnitsListError,
  } = useBusinessUnitsListWrapper(
    customerId as string,
    redirectPageURL,
    isGlobalUser,
    userInfo?.user?.id !== '',
  )

  useEffect(() => {
    if (
      businessUnitsList &&
      businessUnitsList.length > 0 &&
      !isBusinessUnitsListError &&
      !isBusinessUnitsListLoading
    ) {
      const buListConverted: IselectOptionProps[] = businessUnitsList.map(
        (bu: iBusinessUnitDetails) => ({
          label: bu.name,
          value: bu.id,
          customerId: bu.customerId,
        }),
      )
      setBusinessUnitsListConverted(buListConverted)
    }
  }, [businessUnitsList, isBusinessUnitsListError, isBusinessUnitsListLoading])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      isValid,
      isValidating,
      touchedFields,
      isSubmitSuccessful,
      isSubmitted,
      dirtyFields,
    },
  } = useForm<EditOperatorFormType>({
    defaultValues: defaultValues ?? {},
    criteriaMode: 'all',
    shouldFocusError: true,
    mode: 'onTouched',
  })

  const queryClient = useQueryClient()

  const afterDeleteEffects = () => {
    queryClient.invalidateQueries({
      queryKey: ['operators'],
    })
    setTimeout(() => {
      setIsOpened(false)
      setShowConfirmDeleteModal(false)
    }, 500)
  }

  const updateOperatorMutation = useMutation(updateOperator, {
    mutationKey: operatorUpdateMutationKey(),
    onSuccess: (data) => {
      console.log(data)
      console.log('Operator updated successfully')
      afterDeleteEffects()
    },
    onError: (error: Error) => {
      console.log('Error in updating operator', error)
    },
  })

  const {
    isLoading,
    isSuccess: isUpdateOperatorMutationSuccess,
    isError: isUpdateOperatorMutationError,
    error: updateOperatorMutationError,
  } = updateOperatorMutation

  const handleClose = () => {
    setIsOpened(false)
    setShowConfirmDeleteModal(false)
  }

  const onSubmit = async (data: EditOperatorFormType) => {
    console.log(data)
    const updateDTO: iUpdateOperatorDTO = {
      Id: operatorId,
      FirstName: data.FirstName,
      LastName: data.LastName,
      UniqueId: data.UniqueId,
      Status: data.Status.value as string,
      BusinessUnitId: data.BusinessUnit?.value?.toString() || '',
    }

    updateOperatorMutation.mutate({
      msalContext,
      updateOperatorDTO: updateDTO,
    })
  }

  const onError = (formErrors: FieldErrors) => {
    console.log('Form errors: ', formErrors)
  }

  const {
    data: operatorAssignmentHistory,
    isLoading: isOperatorAssignmentHistoryLoading,
    isError: isOperatorAssignmentHistoryError,
    error: operatorAssignmentHistoryError,
    isSuccess: isOperatorAssignmentHistorySuccess,
    status: apiStatus,
  } = useDoesOperatorHaveAssignmentHistory(operatorId, redirectPageURL)

  useEffect(() => {
    if (businessUnitsListError) {
      genericEventHandler({
        error: businessUnitsListError,
        onlyTrack: true,
        severity: 'error',
        message: businessUnitsListError?.message || 'Error fetching business units list',
        extraData: {
          component: 'EditOperator',
          action: 'fetching business units list',
        },
      })
    }

    if (isOperatorAssignmentHistoryError) {
      genericEventHandler({
        error: operatorAssignmentHistoryError,
        onlyTrack: true,
        severity: 'error',
        message:
          operatorAssignmentHistoryError?.message || 'Error fetching operator assignment history',
        extraData: {
          component: 'EditOperator',
          action: 'fetching operator assignment history',
        },
      })
    }

    if (isUpdateOperatorMutationError) {
      genericEventHandler({
        error: updateOperatorMutationError,
        onlyTrack: true,
        severity: 'error',
        message: updateOperatorMutationError?.message || 'Error updating operator',
        extraData: {
          component: 'EditOperator',
          action: 'updating operator',
        },
      })
    }
  }, [
    businessUnitsListError,
    businessUnitsListError,
    isOperatorAssignmentHistoryError,
    operatorAssignmentHistoryError,
    isUpdateOperatorMutationError,
    updateOperatorMutationError,
  ])

  useEffect(() => {
    if (
      isOperatorAssignmentHistoryError &&
      operatorAssignmentHistoryError &&
      (operatorAssignmentHistoryError as Error).message === '404' // Hard coding 404 error code for no assignment history
    ) {
      setCanOperatorBeDeleted(true)
    }
  }, [isOperatorAssignmentHistoryLoading])

  return (
    <div>
      <div className='font-poppins w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all  h-full  text-c-dark-blue-1 font-bold text-3xs md:w-363 md:h-417 md:pt-6 md:px-6'>
        <div className='flex flex-col '>
          <div className='flex flex-row gap-2'>
            <div className='h-11 w-full font-bold text-base pt-2 leading-5  text-c-dark-blue-1'>
              Edit operator
            </div>
            <div>
              <CloseIcon
                toggleClick={() => handleClose()}
                className='bg-c-dark-blue-1 rounded-full w-10 h-10 p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
              />
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
              <div className='flex flex-col w-72 pt-4 md:w-80 md:pt-4 gap-2'>
                <div className='flex flex-row w-full space-x-2  h-16'>
                  <div className='w-1/2'>
                    <label className='px-3' htmlFor='editOperatorFirstName'>
                      First Name
                      <div className='py-1'>
                        <Controller
                          name='FirstName'
                          control={control}
                          rules={{
                            required: 'First name is required',
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              id='editOperatorFirstName'
                              className='h-11 w-full border-3 '
                              readOnly
                              elementProps={{
                                value: field.value,
                                defaultValue: field.value,
                                placeholder: '',
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                  field.onChange(e.target.value?.trim()),
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='pl-2 text-3xs text-red-500'>{errors.FirstName?.message}</p>
                      </div>
                    </label>
                  </div>

                  <div className='w-1/2'>
                    <label className='px-3' htmlFor='editOperatorFormLastName'>
                      Last Name
                    </label>

                    <Controller
                      name='LastName'
                      control={control}
                      rules={{
                        required: 'Last name is required',
                      }}
                      render={({ field }) => (
                        <TextInputControl
                          id='editOperatorLastName'
                          className='h-11 w-full border-3 '
                          readOnly
                          elementProps={{
                            value: field.value,
                            defaultValue: field.value,
                            placeholder: '',
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                              field.onChange(e.target.value?.trim()),
                            onBlur: () => {
                              field.onBlur()
                            },
                          }}
                        />
                      )}
                    />
                    <p className='pl-2 text-3xs text-red-500'>{errors.LastName?.message}</p>
                  </div>
                </div>
                <div className='h-16'>
                  <label className='px-3' htmlFor='editOperatorFormBusinessunit'>
                    Business Unit
                    <div className='py-1'>
                      <Controller
                        name='BusinessUnit'
                        control={control}
                        rules={{
                          required: 'Business unit is required',
                        }}
                        render={({ field }) => (
                          <SelectControl
                            id='editOperatorFormBusinessunit'
                            className='h-11 rounded-3xl'
                            selectControlProps={{
                              isLoading: isBusinessUnitsListLoading,
                              options: businessUnitsListConverted.filter(
                                (bus) =>
                                  bus.value !== 'all' &&
                                  bus.value !== '' &&
                                  rowData.customerId ===
                                    (bus as unknown as IselectOptionProps & { customerId: string })
                                      .customerId,
                              ),
                              // getOptionLabel: (option: iBusinessUnitDetails) => option.name,
                              // getOptionvalue: (option: iBusinessUnitDetails) => option.id,
                              defaultValue: field.value,
                              isMulti: false,
                              isSearchable: true,
                              isDropDownSelectable: true,
                              openMenuOnClick: true,
                              placeholder: 'Select Business unit',
                              isClearable: false,
                              onChange: (selectedOption: IselectOptionProps) => {
                                field.onChange(selectedOption)
                                setSelectedBusinessUnit(selectedOption)
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  </label>
                </div>

                <div className='h-16'>
                  <label className='px-3' htmlFor='editOperatorFormID'>
                    ID
                    <div className='py-1'>
                      <Controller
                        name='UniqueId'
                        control={control}
                        render={({ field }) => (
                          <TextInputControl
                            id='editOperatorID'
                            className='h-11 w-full border-3 '
                            readOnly
                            elementProps={{
                              onChange: (e: string) => field.onChange(e),
                              defaultValue: field.value,
                            }}
                          />
                        )}
                      />
                    </div>
                  </label>
                </div>
                <div className='flex flex-col mt-2'>
                  <label
                    htmlFor='status'
                    className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                  >
                    Status
                  </label>
                  <Controller
                    name='Status'
                    control={control}
                    render={({ field }) => (
                      <SelectControl
                        id='editDeviceFormDeviceStatus'
                        className='h-11 rounded-3xl'
                        selectControlProps={{
                          options: [
                            {
                              label: CustomerStatusConstants[CustomerStatusEnums.Active],
                              value: CustomerStatusEnums.Active,
                            },
                            {
                              label: CustomerStatusConstants[CustomerStatusEnums.Inactive],
                              value: CustomerStatusEnums.Inactive,
                            },
                          ],
                          defaultValue: field.value,
                          isMulti: false,
                          isSearchable: true,
                          isDropDownSelectable: true,
                          openMenuOnClick: true,
                          placeholder: 'Select an option',
                          isClearable: false,
                          onChange: field.onChange,
                        }}
                      />
                    )}
                  />

                  <p className='text-xs text-red-500'>{errors.Status?.message}</p>
                </div>

                <div className='w-full gap-2 pt-3 md:pt-8 flex flex-row justify-center items-center'>
                  <button
                    className={clsx('rounded-full w-14 h-12 flex items-center justify-center ', {
                      'cursor-not-allowed bg-c-light-gray-1 hover:bg-gray-500':
                        !canOperatorBeDeleted,
                      'cursor-pointer bg-c-red-1  hover:bg-c-red-2 active:bg-c-red-3':
                        canOperatorBeDeleted,
                    })}
                    type='button'
                    disabled={!canOperatorBeDeleted}
                    onClick={() => {
                      setShowConfirmDeleteModal(true)
                    }}
                  >
                    {isOperatorAssignmentHistoryLoading ? (
                      <Spinner className='w-5 h-5' />
                    ) : (
                      <DeleteIcon />
                    )}
                  </button>

                  <CrowconButton
                    useDefaultTextColour={false}
                    additionalClassName='text-sm text-c-dark-blue-1  font-bold mt-1 bg-c-light-blue-2 hover:text-white h-12  w-28 rounded-3xl'
                    buttonChildren={<span>Cancel</span>}
                    buttonAction={() => handleClose()}
                  />

                  <button
                    type='submit'
                    className={clsx(
                      'text-sm w-48 h-[48px] mt-1 px-3 rounded-3xl  bg-c-dark-blue-1  outline-0  focus:bg-c-dark-blue-4   hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3  disabled:bg-c-light-blue-2 disabled:text-c-dark-blue-1',
                      !isDirty || !isValid
                        ? 'text-c-dark-blue-1 bg-c-light-blue-2 hover:text-c-white'
                        : 'text-c-white bg-c-dark-blue-1 ',
                    )}
                    disabled={!isDirty || !isValid || isSubmitting}
                  >
                    <div className='px-3 md:px-5 flex flex-row py-1.5 gap-1 justify-center '>
                      {isLoading && <Spinner className='w-5 h-5' />}
                      {isUpdateOperatorMutationSuccess && <TickIcon className='w-5 h-5' />}
                      {isUpdateOperatorMutationError && (
                        <AlertIcon fill='#ff0000' className='w-5 h-5 ' />
                      )}
                      <div className='flex-grow'> Apply </div>
                    </div>
                  </button>
                </div>
              </div>
              {isUpdateOperatorMutationError && (
                <div className='text-xs text-red-500'>
                  {updateOperatorMutationError?.message ?? ''}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <div>
        {/* {showConfirmDeleteModal && ( */}
        <Transition appear show={showConfirmDeleteModal} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-50'
            onClose={() => setShowConfirmDeleteModal(false)}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black/25' />
            </Transition.Child>

            <div className='fixed inset-0 overflow-y-auto'>
              <div className='flex min-h-full items-center justify-center p-4 text-center'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Dialog.Panel className='font-poppins  max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-3 text-left align-middle shadow-xl transition-all  h-full  text-c-dark-blue-1 font-bold text-xs w-80'>
                    <ConfirmDeleteOperator
                      OperatorId={operatorId}
                      OperatorName={`${defaultValues?.FirstName} ${defaultValues?.LastName}`}
                      UniqueId={`${defaultValues?.UniqueId || ''}`}
                      setShowConfirmDeleteModal={(val) => {
                        console.log(val)
                        afterDeleteEffects()
                      }}
                    />
                    {/* <div className='px-10'>Do you want to Delete</div>
                    <div className='px-10'>{`${defaultValues?.FirstName}     ${defaultValues?.LastName} ?`}</div>
                   {defaultValues?.UniqueId && (<div className='px-10'> ID: {`${defaultValues?.UniqueId||''}`}</div>)}
                    <div className='flex flex-row gap-2 pt-4'>
                      <button
                        className='text-c-white text-sm w-48 h-[48px] mt-1 px-3 rounded-3xl  bg-c-dark-blue-1  outline-0   hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3  disabled:bg-c-light-blue-2'
                        type='button'
                        onClick={()=>deleteCurrentOperator()}
                      >
                        Yes
                      </button>
                      <CrowconButton
                        useDefaultTextColour={false}
                        additionalClassName='text-sm text-c-dark-blue-1  font-bold mt-1 bg-c-light-blue-2 hover:text-white h-12  w-28 rounded-3xl'
                        buttonChildren={<span>NO</span>}
                        buttonAction={() => setShowConfirmDeleteModal(false)}
                      />
                    </div> */}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        {/* )} */}
      </div>
    </div>
  )
}

export default EditOperator
